<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Consolidation financière</p>
    </div>

    <v-form>
      <v-card class="mb-6" v-show="isVisibleStep(0)">
        <v-card-title>Initialisation</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-container class="text-center">
                <v-select
                  outlined
                  dense
                  v-model="initMode"
                  :items="initModes"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Configuration"
                  item-text="name"
                  item-value="id"
                  @keyup="checkNext('step-0-0')"
                  :disabled="isSubStepDisabled(0, 0)"
                >
                  <!-- Template to render selected data -->
                  <template slot="selection" slot-scope="data">
                    <v-chip
                      :inputValue="data.selected"
                      close
                      class="chip--select-multi"
                      @input="remove(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <!-- Template to render data / optgroup when the select is expanded -->
                  <template slot="item" slot-scope="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          typeof data.item === "object"
                            ? data.item.name /* Normal item */
                            : data.item /* Divider and header */
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-container>
            </v-col>

            <v-col
              cols="12"
              v-if="showJsonConfigProcess"
              v-show="isVisibleSubStep(0, 1)"
            >
              <v-container class="text-center">
                <v-textarea
                  outlined
                  v-model="config_json"
                  label="Config JSON"
                  @keyup="checkNext('step-0-1')"
                  :disabled="isSubStepDisabled(0, 1)"
                ></v-textarea>
              </v-container>
            </v-col>
          </v-row>

          <prev-next
            v-if="steps.current.step === 0"
            :canPrev="canPrev"
            :canNext="canNext"
            :canReset="canReset"
            @prevStep="prevStep"
            @nextStep="nextStep"
            @resetStep="resetStep"
          />
        </v-card-text>
      </v-card>

      <v-card class="mb-6" v-show="isVisibleStep(1)">
        <v-card-title>Configuration</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.export_file_name"
                  dense
                  label="Nom du fichier qui sera téléchargé"
                  @keyup="checkNext('step-1-0')"
                  :disabled="isSubStepDisabled(1, 0)"
                ></v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" v-show="isVisibleSubStep(1, 1)">
              <v-container class="text-center">
                <date-filter
                  :initialValue="config.monthDate"
                  format="month"
                  :isRange="false"
                  @valueChanged="
                    (val) => {
                      updateMonthDate(val);
                      checkNext('step-1-1');
                    }
                  "
                  :disabled="isSubStepDisabled(1, 1)"
                  periodType="past-only"
                />
              </v-container>
            </v-col>
            <v-col cols="12" v-show="isVisibleSubStep(1, 2)">
              <v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.currency_conversion_rate"
                  type="number"
                  dense
                  label="Taux de conversion USD => Euro"
                  @keyup="checkNext('step-1-2')"
                  :disabled="isSubStepDisabled(1, 2)"
                ></v-text-field>
              </v-container>
            </v-col>
          </v-row>

          <prev-next
            v-if="steps.current.step === 1"
            :canPrev="canPrev"
            :canNext="canNext"
            :canReset="canReset"
            @prevStep="prevStep"
            @nextStep="nextStep"
            @resetStep="resetStep"
          />
        </v-card-text>
      </v-card>

      <v-card class="mb-6" v-show="isVisibleStep(2)">
        <v-card-title>Données des plateformes</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.platformData.url"
                  dense
                  label="URL (google spreadsheet)"
                  @keyup="checkNext('step-2-0')"
                  :disabled="isSubStepDisabled(2, 0)"
                ></v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" v-show="isVisibleSubStep(2, 1)">
              <v-container class="text-center">
                <v-progress-circular
                  v-if="isLoadingStep('step-2-1')"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <v-select
                  v-else
                  outlined
                  dense
                  v-model="config.platformData.sheetId"
                  :items="platformData.sheetNames"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Sheet name"
                  item-text="name"
                  item-value="id"
                  @change="checkNext('step-2-1')"
                  :disabled="isSubStepDisabled(2, 1)"
                ></v-select>
              </v-container>
            </v-col>
            <v-col cols="12" v-show="isVisibleSubStep(2, 2)">
              <v-container class="text-center">
                <v-select
                  outlined
                  dense
                  v-model="config.platformData.repartitionMode"
                  :items="repartitionModes"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Mode de répartition"
                  item-text="name"
                  item-value="id"
                  @keyup="checkNext('step-2-2')"
                  :disabled="isSubStepDisabled(2, 2)"
                ></v-select>
              </v-container>
            </v-col>
          </v-row>

          <prev-next
            v-if="steps.current.step === 2"
            :canPrev="canPrev"
            :canNext="canNext"
            :canReset="canReset"
            @prevStep="prevStep"
            @nextStep="nextStep"
            @resetStep="resetStep"
          />
        </v-card-text>
      </v-card>

      <v-card class="mb-6" v-show="isVisibleStep(3)">
        <v-card-title
          v-if="
            config.platformData.repartitionMode === REPARTITION_MODES.PUBSTACK
          "
          >Données Pubstack</v-card-title
        >
        <v-card-title v-else>Données GAM</v-card-title>
        <v-card-text
          v-show="
            config.platformData.repartitionMode === REPARTITION_MODES.PUBSTACK
          "
        >
          <v-row>
            <v-col cols="12"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.pubstack.url"
                  dense
                  label="URL (google spreadsheet)"
                  @keyup="checkNext('step-3-0')"
                  :disabled="isSubStepDisabled(3, 0)"
                ></v-text-field>
              </v-container>
            </v-col>
          </v-row>

          <prev-next
            v-if="steps.current.step === 3"
            :canPrev="canPrev"
            :canNext="canNext"
            :canReset="canReset"
            @prevStep="prevStep"
            @nextStep="nextStep"
            @resetStep="resetStep"
          />
        </v-card-text>

        <v-card-text
          v-show="config.platformData.repartitionMode === REPARTITION_MODES.GAM"
        >
          <v-row>
            <v-col cols="12"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.gam.prebid.url_display"
                  dense
                  label="URL Prebid Display (CSV)"
                  @keyup="checkNext('step-3-0')"
                  :disabled="isSubStepDisabled(3, 0)"
                ></v-text-field>
              </v-container>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" v-show="isVisibleSubStep(3, 1)"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.gam.prebid_by_pubstack.url_display"
                  dense
                  label="URL Prebid by Pubstack Display (CSV)"
                  @keyup="checkNext('step-3-1')"
                  :disabled="isSubStepDisabled(3, 1)"
                ></v-text-field>
              </v-container>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" v-show="isVisibleSubStep(3, 2)"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.gam.prebid_by_pubstack.url_video"
                  dense
                  label="URL Prebid by Pubstack Vidéo (CSV)"
                  @keyup="checkNext('step-3-2')"
                  :disabled="isSubStepDisabled(3, 2)"
                ></v-text-field>
              </v-container>
            </v-col>
          </v-row>

          <prev-next
            v-if="steps.current.step === 3"
            :canPrev="canPrev"
            :canNext="canNext"
            :canReset="canReset"
            @prevStep="prevStep"
            @nextStep="nextStep"
            @resetStep="resetStep"
          />
        </v-card-text>
      </v-card>

      <v-card class="mb-6" v-show="isVisibleStep(4)">
        <v-card-title>Données Amazon</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.amazon.url_display"
                  dense
                  label="URL display (CSV)"
                  @keyup="checkNext('step-4-0')"
                  :disabled="isSubStepDisabled(4, 0)"
                ></v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" v-show="isVisibleSubStep(4, 1)"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.amazon.url_video"
                  dense
                  label="URL vidéo (CSV)"
                  @keyup="checkNext('step-4-1')"
                  :disabled="isSubStepDisabled(4, 1)"
                ></v-text-field>
              </v-container>
            </v-col>
          </v-row>

          <prev-next
            v-if="steps.current.step === 4"
            :canPrev="canPrev"
            :canNext="canNext"
            :canReset="canReset"
            @prevStep="prevStep"
            @nextStep="nextStep"
            @resetStep="resetStep"
          />
        </v-card-text>
      </v-card>

      <v-card class="mb-6" v-show="isVisibleStep(5)">
        <v-card-title>Données Browsi</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.browsi.url_o_o_browsi_demand"
                  dense
                  label="URL O_O BrowsiDemand (CSV)"
                  @keyup="checkNext('step-5-0')"
                  :disabled="isSubStepDisabled(5, 0)"
                ></v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" v-show="isVisibleSubStep(5, 1)"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.browsi.url_rev_optimize_browsi_demand"
                  dense
                  label="URL RevOptimize BrowsiDemand (CSV)"
                  @keyup="checkNext('step-5-1')"
                  :disabled="isSubStepDisabled(5, 1)"
                ></v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" v-show="isVisibleSubStep(5, 2)"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.browsi.url_o_o_reworld_demand"
                  dense
                  label="URL O_O ReworldDemand (CSV)"
                  @keyup="checkNext('step-5-2')"
                  :disabled="isSubStepDisabled(5, 2)"
                ></v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" v-show="isVisibleSubStep(5, 3)"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.browsi.url_rev_optimize_reworld_demand"
                  dense
                  label="URL RevOptimize ReworldDemand (CSV)"
                  @keyup="checkNext('step-5-3')"
                  :disabled="isSubStepDisabled(5, 3)"
                ></v-text-field>
              </v-container>
            </v-col>
          </v-row>

          <prev-next
            v-if="steps.current.step === 5"
            :canPrev="canPrev"
            :canNext="canNext"
            :canReset="canReset"
            @prevStep="prevStep"
            @nextStep="nextStep"
            @resetStep="resetStep"
          />
        </v-card-text>
      </v-card>

      <v-card class="mb-6" v-show="isVisibleStep(6)">
        <v-card-title>Données Xandr</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-container class="text-center">
                <v-text-field
                  outlined
                  v-model="config.xandr.url"
                  dense
                  label="URL Xandr"
                  @keyup="checkNext('step-6-0')"
                  :disabled="isSubStepDisabled(6, 0)"
                ></v-text-field>
              </v-container>
            </v-col>
          </v-row>

          <prev-next
            v-if="steps.current.step === 6"
            :canPrev="canPrev"
            :canNext="canNext"
            :canReset="canReset"
            @prevStep="prevStep"
            @nextStep="nextStep"
            @resetStep="resetStep"
          />
        </v-card-text>
      </v-card>

      <v-btn
        v-if="canSubmit"
        color="primary"
        class="me-3 mt-3"
        @click.native="submit"
      >
        Générer le rapport</v-btn
      >
    </v-form>

    <consolidation-table
      v-show="isResultVisible"
      :config="config"
      :applyFilters="submitting"
      @reloaded="setReloaded"
    />
  </div>
</template>

<script>
import axios from "@axios";
import every from "lodash/every";
import { required } from "@core/utils/validation";

import DateFilter from "@/components/common/filters/DateFilter";
import PrevNext from "@/components/finance/actions/PrevNext";
import ConsolidationTable from "@/components/finance/tables/Consolidation.vue";
import {
  getDate,
  getDateDetails,
  getMonthDateStr,
  monthsFrArray,
} from "@/utils/dates";

const INIT_MODES = {
  RUN_LAST_CONFIG: "run_last_config",
  LOAD_LAST_CONFIG: "load_last_config",
  RUN_JSON_CONFIG: "run_json_config",
  LOAD_JSON_CONFIG: "load_json_config",
  LOAD_DEFAULT_CONFIG: "load_default_config",
};

const INIT_OPT_GROUPS = {
  RUN: "Lancement tel quel",
  LOAD: "Modifiable point par point",
};

const REPARTITION_MODES = {
  PUBSTACK: "pubstack",
  GAM: "gam",
};

// Nb of subSteps for each step :
const DEFAULT_STEPS = [
  0, // Step 0 => INIT (may change depending on selected INIT_MODES)
  2, // Step 1 => Config
  2, // Step 2 => DATA FROM PLATFORMS
  2, // Step 3 => PUBSTACK (may change depending on prev config selected options)
  1, // Step 4 => Amazon
  3, // Step 5 => Browsi
  0, // Step 6 => Xandr
];

const JSON_CONFIG_STEPS = [
  1, // Step 0 => INIT (only json upload field)
];

const LAST_CONFIG_STEPS = [
  0, // Step 0 => INIT (directly run last saved config)
];

const DEFAULT_CONFIG = {
  export_file_name: "Consolidation_Financiere.xlsx",
  monthDate: "",
  monthName: "",
  currency_conversion_rate: 1, // 0.92495,
  platformData: {
    url: "",
    sheetName: "",
    sheetId: 0,
    repartitionMode: REPARTITION_MODES.GAM,
  },
  pubstack: {
    url: "",
  },
  gam: {
    prebid: {
      url_display: "",
    },
    prebid_by_pubstack: {
      url_display: "",
      url_video: "",
    },
  },
  amazon: {
    url_display: "",
    url_video: "",
  },
  browsi: {
    url_o_o_browsi_demand: "",
    url_rev_optimize_browsi_demand: "",
    url_o_o_reworld_demand: "",
    url_rev_optimize_reworld_demand: "",
  },
  xandr: {
    url: "",
  },
};

export default {
  name: "ConsolidationReport",
  components: {
    ConsolidationTable,
    DateFilter,
    PrevNext,
  },
  data() {
    return {
      submitting: false,
      dataLoaded: false,
      REPARTITION_MODES,
      SUB_STEPS: DEFAULT_STEPS,
      steps: {
        current: {
          loading: false,
          step: 0,
          subStep: 0,
        },
        history: [],
        canNext: false,
      },
      initModes: [
        { header: INIT_OPT_GROUPS.RUN },
        {
          id: INIT_MODES.RUN_LAST_CONFIG,
          name: "Relancer avec la dernière config",
          group: INIT_OPT_GROUPS.RUN,
          disabled: false,
        },
        {
          id: INIT_MODES.RUN_JSON_CONFIG,
          name: "Lancer avec une config JSON",
          group: INIT_OPT_GROUPS.RUN,
          disabled: false,
        },
        { divider: true },
        { header: INIT_OPT_GROUPS.LOAD },
        {
          id: INIT_MODES.LOAD_LAST_CONFIG,
          name: "Précharger la dernière config",
          group: INIT_OPT_GROUPS.LOAD,
          disabled: false,
        },
        {
          id: INIT_MODES.LOAD_JSON_CONFIG,
          name: "Précharger une config JSON",
          group: INIT_OPT_GROUPS.LOAD,
          disabled: false,
        },
        {
          id: INIT_MODES.LOAD_DEFAULT_CONFIG,
          name: "Repartir de zéro",
          group: INIT_OPT_GROUPS.LOAD,
          disabled: false,
        },
      ],
      initMode: INIT_MODES.LOAD_DEFAULT_CONFIG,
      repartitionModes: [
        {
          id: REPARTITION_MODES.PUBSTACK,
          name: "Pubstack",
        },
        {
          id: REPARTITION_MODES.GAM,
          name: "GAM",
        },
      ],
      config_json: "",
      config: DEFAULT_CONFIG,
      platformData: {
        sheetNames: [],
      },
      required,
      dropdownSelected: (v) => {
        return !!v;
      },
    };
  },
  async mounted() {
    const now = new Date();
    const lastDayOfPrevMonth = getDate(-now.getDate());
    this.config.monthDate = getMonthDateStr(lastDayOfPrevMonth);
    const { month } = getDateDetails(lastDayOfPrevMonth);
    this.config.monthName = monthsFrArray[parseInt(month, 10) - 1];

    const lastStoredConfig =
      localStorage.getItem("config-conso-financiere") ?? "";
    if (lastStoredConfig) {
      // Change default option to use last stored config by default
      this.initMode = INIT_MODES.RUN_LAST_CONFIG;
    } else {
      // Disbale option entries if no previous config has been stored yet :
      this.initModes = this.initModes.map((option) => {
        if (
          [INIT_MODES.LOAD_LAST_CONFIG, INIT_MODES.RUN_LAST_CONFIG].includes(
            option.id ?? ""
          )
        ) {
          return {
            ...option,
            disabled: true,
          };
        }

        return option;
      });
    }

    await this.checkNewStep();
  },
  methods: {
    async submit() {
      this.submitting = true;

      localStorage.setItem(
        "config-conso-financiere",
        JSON.stringify(this.config)
      );
      // console.log("Saved config", this.config);
    },
    isValidConfig(config) {
      const key_validations = Object.keys(DEFAULT_CONFIG).map(
        (key) => key in config
      );

      return every(key_validations);
    },
    loadConfig(config) {
      if (!this.isValidConfig(config)) {
        alert(
          "La configuration choisie n'est plus valide : chargement de la config par défaut"
        );
        this.loadDefaultConfig();

        return;
      }

      this.config = config;
      // Force user to reselect sheetName/sheetId
      this.config.platformData.sheetName = "";
      this.config.platformData.sheetId = 0;

      // console.log("loadConfig", this.config);
    },
    loadDefaultConfig() {
      this.config = DEFAULT_CONFIG;
      this.initMode = INIT_MODES.LOAD_DEFAULT_CONFIG;
    },
    loadLastConfig() {
      const lastStoredConfig =
        localStorage.getItem("config-conso-financiere") ?? "";
      if (!lastStoredConfig) {
        alert(
          "Aucune configuration n'a encore été enregistrée : chargement de la config par défaut"
        );
        this.loadDefaultConfig();

        return;
      }

      // console.log("last stored config", lastStoredConfig);
      this.loadConfig(JSON.parse(lastStoredConfig));
    },
    async ["step-2-1"]() {
      this.steps.current.loading = true;
      const { data } = await axios.get(
        `/finance/data-from-platform/sheetnames`,
        { params: { url: this.config.platformData.url } }
      );
      this.platformData.sheetNames = data.items;

      this.steps.current.loading = false;
    },
    async checkNewStep() {
      const ref = this.stepRefName;
      if (ref in this) {
        await this[ref]();
      }

      this.checkNext();
    },
    getRefValue() {
      switch (this.stepRefName) {
        case "step-0-0":
          return this.initMode;
        case "step-0-1":
          return this.config_json;

        case "step-1-0":
          return this.config.export_file_name;
        case "step-1-1":
          return this.config.monthDate;
        case "step-1-2":
          return this.config.currency_conversion_rate;

        case "step-2-0":
          return this.config.platformData.url;
        case "step-2-1":
          return this.config.platformData.sheetId;
        case "step-2-2":
          return this.config.platformData.repartitionMode;

        case "step-3-0":
          switch (this.config.platformData.repartitionMode) {
            case REPARTITION_MODES.PUBSTACK:
              return this.config.pubstack.url;
            case REPARTITION_MODES.GAM:
              return this.config.gam.prebid.url_display;
            default:
              return "";
          }
        case "step-3-1":
          return this.config.gam.prebid_by_pubstack.url_display;
        case "step-3-2":
          return this.config.gam.prebid_by_pubstack.url_video;

        case "step-4-0":
          return this.config.amazon.url_display;
        case "step-4-1":
          return this.config.amazon.url_video;

        case "step-5-0":
          return this.config.browsi.url_o_o_browsi_demand;
        case "step-5-1":
          return this.config.browsi.url_rev_optimize_browsi_demand;
        case "step-5-2":
          return this.config.browsi.url_o_o_reworld_demand;
        case "step-5-3":
          return this.config.browsi.url_rev_optimize_reworld_demand;

        case "step-6-0":
          return this.config.xandr.url;

        default:
          return "";
      }
    },
    checkNext() {
      this.steps.canNext = !!this.getRefValue();
    },
    resetStep() {
      this.steps.current = {
        step: 0,
        subStep: 0,
      };
      this.steps.history = [];
      this.steps.canNext = false;
    },
    async nextStep() {
      const maxSubStep = this.SUB_STEPS[this.steps.current.step];
      if (this.steps.current.subStep >= maxSubStep) {
        if (this.steps.current.step < this.SUB_STEPS.length) {
          // if (this.steps.current.step > 0) {
          // Don't go back to init: first step is enough
          this.steps.history.push({ ...this.steps.current });
          // }

          this.steps.current.step += 1;
          this.steps.current.subStep = 0;
          this.steps.canNext = false;

          await this.checkNewStep();
        }

        return;
      }

      this.steps.history.push({ ...this.steps.current });
      this.steps.current.subStep += 1;
      this.steps.canNext = false;

      await this.checkNewStep();
    },
    prevStep() {
      if (this.steps.history.length > 0) {
        this.steps.current = { ...this.steps.history.pop() };

        this.checkNext();
      }
    },
    updateMonthDate(newDateStr) {
      this.config.monthDate = newDateStr;
    },
    async setReloaded() {
      this.dataLoaded = true;
      this.submitting = false;

      this.resetStep();
      await this.checkNewStep();
    },
  },
  computed: {
    stepRefName() {
      return `step-${this.steps.current.step}-${this.steps.current.subStep}`;
    },
    isLoadingStep() {
      return (step) => this.stepRefName === step && this.steps.current.loading;
    },
    isVisibleStep() {
      return (step) => this.steps.current.step === step;
    },
    isVisibleSubStep() {
      return (step, subStep) =>
        this.steps.current.step > step || this.steps.current.subStep >= subStep;
    },
    isSubStepDisabled() {
      return (step, subStep) =>
        this.steps.current.step > step || this.steps.current.subStep > subStep;
    },
    isResultVisible() {
      return this.submitting || this.dataLoaded;
    },
    canSubmit() {
      return (
        this.steps.current.step === this.SUB_STEPS.length - 1 &&
        this.steps.current.subStep ===
          this.SUB_STEPS[this.steps.current.step] &&
        !this.submitting &&
        this.steps.canNext
      );
    },
    canNext() {
      if (!this.steps.canNext) {
        return false;
      }

      const maxSubStep = this.SUB_STEPS[this.steps.current.step];

      return (
        this.steps.current.subStep < maxSubStep ||
        this.steps.current.step < this.SUB_STEPS.length - 1
      );
    },
    canPrev() {
      return this.steps.history.length > 0;
    },
    canReset() {
      return this.steps.current.step > 0 || this.steps.current.subStep > 0;
    },
    showJsonConfigProcess() {
      return [INIT_MODES.LOAD_JSON_CONFIG, INIT_MODES.RUN_JSON_CONFIG].includes(
        this.initMode
      );
    },
  },
  watch: {
    ["config.platformData.repartitionMode"]() {
      switch (this.config.platformData.repartitionMode) {
        case REPARTITION_MODES.GAM:
          this.SUB_STEPS[2] = 2;
          break;

        case REPARTITION_MODES.PUBSTACK:
        default:
          this.SUB_STEPS[2] = 0;
          break;
      }
    },
    ["config.platformData.sheetId"]() {
      const sheet = this.platformData.sheetNames.find(
        (item) => item.id === this.config.platformData.sheetId
      );
      this.config.platformData.sheetName = sheet?.name ?? "";
    },
    ["config.monthDate"]() {
      const [_, month] = this.config.monthDate.split("-");
      this.config.monthName = monthsFrArray[parseInt(month, 10) - 1];
    },
    initMode() {
      switch (this.initMode) {
        case INIT_MODES.LOAD_JSON_CONFIG:
          this.SUB_STEPS = DEFAULT_STEPS.map((val, index) =>
            index === 0 ? 1 : val
          ); // Show JSON field + other steps
          break;

        case INIT_MODES.RUN_JSON_CONFIG:
          this.SUB_STEPS = JSON_CONFIG_STEPS; // Show only JSON field
          break;

        default:
          if (
            [INIT_MODES.LOAD_LAST_CONFIG, INIT_MODES.RUN_LAST_CONFIG].includes(
              this.initMode
            )
          ) {
            this.loadLastConfig();
          }

          this.SUB_STEPS =
            this.initMode === INIT_MODES.RUN_LAST_CONFIG
              ? LAST_CONFIG_STEPS // Show nothing more : directly run report
              : DEFAULT_STEPS;
          break;
      }
    },
    config_json() {
      if (this.showJsonConfigProcess && this.config_json.length > 0) {
        this.loadConfig(JSON.parse(this.config_json));
      }
    },
  },
};
</script>

<style scoped></style>
